import React,{useState,useEffect} from "react";
import Container from "react-bootstrap/Container";
import CatalogoEquipos from "./CatalogoEquipos";
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from "gatsby";

import styled from "styled-components";
import Galery from '../galery/galery';
import OtrosYClientes from "./otrosyclientes";
import IconGallery from "../../../images/gallery.svg";
import Modal from "../galery/modal/modal";


import "./style.scss"

const BGImageServices1 = styled(BackgroundImage)`
  top: 0px;
  opacity: .3!important;
  position: sticky!important;
  width: 100%;
  height: 100vh;
  z-index: 1;
  background-position: center top!important;
  background-repeat: repeat-y;
  background-size: cover;
`;
const Services = ( {data} )=>{

  let window_width = typeof window !== `undefined` ? window.innerWidth : null; 
  const [width,setWidth] = useState( window_width );
  const [isOpen, setIsOpen] = useState(false);

  function handleWindowSizeChange() {
    let window_width = typeof window !== `undefined` ? window.innerWidth : null; 
    setWidth( window_width);
  }
  useEffect(() => {
    if (typeof window !== `undefined`)
      window.addEventListener('resize', handleWindowSizeChange);

    

    return () => {
      if (typeof window !== `undefined`)
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  const data_image =  useStaticQuery(
    graphql`
      query{
        file(relativePath: { eq: "bg-services1.jpg" }) {
          childImageSharp {
            fluid {
              base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
            },
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
      	}
      }
    `
  );

  const Ver = ()=>{
    return(
      <section className='p-5 m-5 bg-white d-flex 
      justify-content-center align-items-center cont-ver-galeria'>
      <div onClick={()=>{setIsOpen(true)}} 
       className="d-flex flex-column justify-content-center align-items-center">
        <IconGallery/>
        <div className="wrapper-ver-galeria">
          <div className="wrap-effect">
            <span className="mb-5 text-ver-galeria">Ver nuestra galeria</span>
          </div>
        </div>
      </div>
      </section>
    );
  };

  const imageDataServ = data_image.file.childImageSharp.fluid;
  return (
    <div id='servicios'>
    <section  className=" cont-services">
     <BGImageServices1 Tag="div" id="contBgServices" fluid={imageDataServ} />
        <div className="pt-5 ps-3 ps-sm-1 pe-3 pe-sm-1 ps-lg-5 pe-lg-5 position-relative 
         wrapper-services">
        <h2   
           data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease"
        className="title ps-3 p-5">
          <span className="title-text">SERVICIOS</span></h2>
        <p  
           data-sal="slide-up"
            data-sal-delay="300"
          data-sal-easing="ease"
          className="col-11 col-md-8 m-auto pb-4 pt-4 mt-5  mb-5  ">
            Nuestro compromiso y experiencia nos permiten brindarle
            las siguientes soluciones enfocadas a mantener el correcto
            funcionamiento de sus equipos electrónicos y con ello
            sus procesos.
          </p>
          <Container 
          className="mb-0 mb-md-5 ms-auto me-auto text-center all-services">
            <div className="d-flex flex-column flex-lg-row flex-wrap-no-wrap all-service-wrapper">
              <div 
              data-sal="slide-right"
            
              data-sal-easing="ease"
              className=" col-lg-4 ps-4 pe-4 all-service-item">
                <h5 className="item-service-title">
                  Mantenimiento preventivo y correctivo
                </h5>
                <div>
                  <p>
                    Mantenimiento preventivo para el optimo 
                    funcionamiento de su equipo electrónico, que a largo plazo 
                    prolongará su tiempo de vida. Y mantenimiento correctivo 
                    para cuando su equipo ya presenta un fallo.
                  </p>
                </div>
              </div>
              <div 
                data-sal="slide-right"
                data-sal-easing="ease"
              className=" col-md-lg-4 ps-4 pe-4 all-service-item">
                <h5 className="item-service-title">
                  Asistencia técnica en planta
                </h5>
                <div>
                  <p>
                    Brindamos soluciones y/o alternativas para el correcto
                    funcionamiento de su proceso con nuestra asistencia 
                    técnica.
                  </p>
                </div>
              </div>
              <div 
                data-sal="slide-right"
                data-sal-easing="ease"
              className=" col-lg-4 ps-4 pe-4 all-service-item">
                <h5 className="item-service-title">
                  Proyectos de automatización
                </h5>
                <div>
                  <p>
                    Elaboramos propuestas para su proyecto de automatización 
                    que se adaptan completamante a sus necesidades.
                  </p>
                </div>
              </div>
            </div>
          </Container>
        <div className="
        wrapper-catalogo-equipos "
        >
          <CatalogoEquipos/>
        </div>
      </div>
      
    </section>

    <div className='pb-5'>  
    {/* width > 620 && <Galery data={data}/> */}
    {/* width < 620 && <Ver/> */}
    <Ver/> 
    {isOpen && <Modal setIsOpen={setIsOpen} images={data.images} indexImage={0} />}
     </div>
    

    
    

    <OtrosYClientes/>
    </div>
  );
};

export default Services;
