import React,{ useState} from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import ViewIcon from "../../../images/view.svg";
import Modal from "./modal/modal";

import './style.scss'

const Galery = ({data})=>{

    const [images] = useState(typeof data !== `undefined` ? data.images : null);
    const [indexImage, setIndexImage] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    
    const Images = images ? images.edges.map((el,i)=>{
        return(
            <figure onClick={()=>{setIsOpen(true);setIndexImage(i)}} 
                key={`fig-${i}`} className="grid-gallery__item">
                <div className='grid-gallery__item__visor d-flex justify-content-center align-items-center'>
                    <ViewIcon  />
                </div>
                
                <GatsbyImage className="grid-gallery__image"
                 key={`img-${i}`}image={el.node.childImageSharp.full} alt="Gallery DELEKTRON"/>
            </figure>
        )
    }) : null;
    
    return(
        <>
        
        <div className=" p-3 grid-gallery">{ Images  }</div>
        {isOpen && <Modal setIsOpen={setIsOpen} images={images} indexImage={indexImage} />}
        </>
    );

};

export default Galery;